import React from "react";

/*
  Inputs:   A string containing text to turn into links (Marked by * at the start of a word)
  Outputs:  A React component containing the original text with the desired text turned into links
*/

const getLink = (string) => {
  // Search for links
  const ad = string.match(/\*\w+/);

  // BASE CASE: No links, return the normal string
  if (ad == null) {
    return string;
  }
  // Extract linkID
  const linkID = ad[0].substring(1);

  // Split at the link position
  const prefix = string.substring(0,string.indexOf(ad));
  const postfix = string.substring(string.indexOf(ad)+ad[0].length);

  // LinkLibrary [Move to CMS]
  const linkLibrary = [
    { id: "HubSpot", displayName: "HubSpot", link: "https://www.hubspot.com"},
    { id: "GetHired", displayName: "GetHired", link: "https://gethired.com/"},
    { id: "GoogleAnalytics", displayName: "Google Analytics", link: "https://analytics.google.com/"},
    { id: "LinkedIn", displayName: "LinkedIn", link: "https://www.linkedin.com/"},
    { id: "Indeed", displayName: "Indeed", link: "https://www.indeed.com/"},
  ]
  // Be default, link is not found
  let ref = "404";
  let name = linkID;
  // Lookup link in linkLibrary, saving the correct link
  for (let i = 0; i < linkLibrary.length; i++) {
    if (linkLibrary[i].id === linkID) {
      ref = linkLibrary[i].link;
      name = linkLibrary[i].displayName;
      break;
    }
  }
  // Return, recursively calling the function on the postfix
  return <>{prefix}<a href={ref} target="_blank" rel="noopener noreferrer">{name}</a>{getLink(postfix)}</>;
};

export { getLink };