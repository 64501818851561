import './privacy-policy.scss';

import Cta from "../components/cta"
import Footer from '../components/footer';
import Header from '../components/header';
import Layout from '../components/layout';
import React from 'react';
import Seo from '../components/seo2';
import Wave from '../components/wave';
import privacyLock from '../../static/images/privacy-lock.svg';
import { getStaticContentByIdentifier } from "../services/misc.service";
import { getStaticImageByIdentifier } from "../services/image.service";
import { getLink } from "../services/link.service";
import { getMailLink  } from "../services/mail.service";
import { getSEOByIdentifier } from "../services/seo.service";

const PrivacyPolicy = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const infoUses = props.pageContext.infoUses;
  const storedInfos = props.pageContext.storedInfos;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('privacy-policy', SEOInfo)}/>
      <Header />
      <main id="maincontent">
        <section className="privacy-hero-section padding-t-5">
          <div className="hero row middle-md">
            <div className="col-xs-12 col-md-6 margin-t-0-xs margin-t-0-md">
              <h1 className="padding-l-3 padding-r-3 text-center">{getStaticContentByIdentifier('privacy.hero.title', staticContent)}</h1>
              <p className="padding-l-3 padding-r-3 text-center">
              {getStaticContentByIdentifier('privacy.hero.content', staticContent)}
              </p>
            </div>
            <div className="col-xs-12 col-md-6 col-no-gutter padding-l-3 padding-r-3 padding-l-6-md padding-r-0-md">
              <img
                src={getStaticImageByIdentifier('privacy.hero.image', staticImages)}
                alt="Orange Bees Privacy"
                className="privacy-illustration"
              />
            </div>
          </div>
          <Wave color="#f8f8f8" background="#ffffff" />
        </section>

        <section className="privacy-content-section margin-t-6 padding-l-4 padding-r-4 padding-l-3-md padding-r-3-md">
          <div className="row mobile-center">
            <p>
            {getMailLink(getStaticContentByIdentifier('privacy.content0', staticContent))}
            </p>
            <h2 className="margin-t-6 margin-b-3 mobile-center">
            {getStaticContentByIdentifier('privacy.subtitle1', staticContent)}
            </h2>
            <div className="bullets">
              { storedInfos.map((x, index) => (
                <div key={index} className="margin-b-2">
                  <div className="triangle"></div>
                  <b>{x.title}: </b>{getLink(x.body)}
              </div>
              ))
              }
            </div>
            <h2 className="margin-t-6 margin-b-3 mobile-center">
            {getStaticContentByIdentifier('privacy.subtitle2', staticContent)}
            </h2>
            <p>
            {getLink(getStaticContentByIdentifier('privacy.content2', staticContent))}
            </p>
            <h2 className="margin-t-6 margin-b-3">
            {getStaticContentByIdentifier('privacy.subtitle3', staticContent)}
            </h2>
            <div className="bullets">
              { infoUses.map((x, index) => (
                <div key={index} className="margin-b-2">
                  <div className="triangle"></div>
                  <b>{x.title}: </b>{getLink(x.body)}
              </div>
              ))
              }
            </div>
            <h2 className="margin-t-6 margin-b-3">
            {getStaticContentByIdentifier('privacy.subtitle4', staticContent)}
            </h2>
            <p>
            {getStaticContentByIdentifier('privacy.content4', staticContent)}
            </p>
            <h2 className="margin-t-6 margin-b-3">
            {getStaticContentByIdentifier('privacy.subtitle5', staticContent)}
            </h2>
            <p>
            {getMailLink(getStaticContentByIdentifier('privacy.content5', staticContent))}
            </p>
            <p>
              <i>
              {getStaticContentByIdentifier('privacy.lastupdated', staticContent)}
              </i>
            </p>
          </div>
        </section>

        <Cta title={getStaticContentByIdentifier('privacy.CTA.content', staticContent)} button={getStaticContentByIdentifier('privacy.CTA.button', staticContent)} />
      </main>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy;